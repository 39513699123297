$('<link>').appendTo('head').attr({
    type: "text/css",
    rel: "stylesheet",
    href: "https://cdnjs.cloudflare.com/ajax/libs/outdated-browser/1.1.5/outdatedbrowser.min.css"
});
let data_ie = $("[data-ie]").data("ie"),
    data_ie_title = $("[data-ie-title]").data("ie-title"),
    data_ie_btn = $("[data-ie-btn]").data("ie-btn"),
    data_lang = $("html").attr("lang");

$(function() {
    $("html").addClass("ie9");

    if (localStorage.getItem('ie') !== "hidden") {
        $("body").prepend(`
            <div id="outdated">
                <h6>${data_ie_title}</h6>
                <p>${data_ie} <a id="btnUpdateBrowser" href="http://outdatedbrowser.com/${data_lang}" target="_blank">${data_ie_btn}</a></p>
                <p class="last"><a href="#" id="btnCloseUpdateBrowser" title="Zavřít">&times;</a></p>
            </div>
        `);
        $("#outdated").show();
        $("#btnCloseUpdateBrowser").on("click", function (e) {
            e.preventDefault();
            $("#outdated").remove();
            localStorage.setItem('ie', 'hidden');
        });
        $("#btnUpdateBrowser").css({
            "display": "table",
            "width": "auto"
        });
    }
});
